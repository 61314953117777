



















import { Vue, Component } from "vue-property-decorator";

@Component({
  name: "detail"
})
export default class extends Vue {
  news = JSON.parse(sessionStorage.getItem("news-detail") ?? `{}`);
}
